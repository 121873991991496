.c-footer {
  margin-top: $spaceGiant;
  background-color: rgb($black);

  &,
  a {
    color: rgb($greyDark);
  }

  @mixin bp $tablet {
    margin-top: $spaceGiantTablet;
  }

  a:hover {
    color: rgb($purple);
  }

  .page-template-template-calendar &,
  .tribe-events-page-template &,
  .tax-tribe_events_cat & {
    display: none;
  }

  &__logo,
  &__contact,
  &__newsletter {
    grid-column-end: span 12;
  }

  &__logo {
    text-align: center;

    @mixin bp $tablet {
      order: 1;
      margin-bottom: $spaceBigTablet;
    }

    svg {
      width: 100%;
      height: 100%;
      max-width: 1194px;
      margin: 0 auto;
    }

    path {
      fill: currentColor;
    }
  }

  &__contact {
    margin-bottom: $spaceBig;
    text-transform: uppercase;

    @mixin bpMax $tablet {
      margin-top: $spaceBig;
    }

    @mixin bp $tablet {
      display: flex;
      justify-content: space-between;
      order: 0;
      margin-bottom: $spaceBigTablet;
      flex-wrap: wrap;
    }

    @mixin bp $laptop {
      flex-wrap: nowrap;
    }

    p {
      margin: 0;
    }
  }

  &__email,
  &__link:not(:last-child) {
    @mixin bp $tablet {
      padding-right: $gutterTablet;
      padding-left: $gutterTablet;
    }
  }

  &__email {
    @mixin bpMax $tablet {
      margin-top: $spaceBig;
      margin-bottom: $spaceBig;
    }
  }

  &__newsletter {
    @mixin bp $tablet {
      order: 2;
    }

    @mixin bpMenuShrunk {
      display: none;
    }

    form {
      flex-wrap: wrap;
    }

    .c-newsletter input[type='email'],
    .c-newsletter input[type='submit'] {
      @mixin bpMax $tablet {
        width: 100%;
      }
    }

    .c-newsletter input[type='email'] {
      @mixin bpMax $tablet {
        margin-top: $spaceSmall;
        margin-right: 0;
      }
    }

    .c-newsletter input[type='submit'] {
      @mixin bpMax $tablet {
        margin-top: $spaceMedium;
        padding-top: 1em;
        padding-bottom: 0.8em;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.c-footer--arthub {
  padding-top: $spaceMedium;
  padding-bottom: $spaceBig;

  @mixin bp $tablet {
    padding-top: $spaceMediumTablet;
    padding-bottom: $spaceBigTablet;
  }
}

.c-footer-rr {
  color: rgb($black);
  grid-column-end: span 12;
  background-color: rgb($greyDark);
  padding-bottom: 150px;

  & a {
    color: rgb($purple);
    text-decoration: none;

    &:hover {
      color: black; /* TODO: update to variable */
    }
  }

  &__logo {
    grid-column-end: span 12;
    position: relative;

    & svg {
      width: 100%;
      height: 100%;
    }

    &--white {
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__description {
    margin-top: 6px;
    grid-column-end: span 12;

    & p {
      margin-top: 0px;
    }
  }

  &__area {
    grid-column-end: span 12;

    & p {
      margin-top: 0px;
    }
  }

  &__horizontal-line {
    width: 100%;
    border: none;
    border-top: 2px solid black;
    margin-top: 24px;
    grid-column-end: span 12;
  }

  @media (min-width: 1024px) {
    padding-bottom: 200px;

    &__area {
      grid-column-end: span 3;
    }

    &__horizontal-line {
      margin-top: 32px;
    }
  }
}

.c-footer.c-footer--rr {
  margin-top: 0;
  @mixin bp $tablet {
    margin-top: 0;
  }
}
