/* .c-main-nav .search-toggle {
    color: #fff;
    will-change: color;
    background-color: transparent;
    border: 0;
    cursor: pointer;
} */

/* .c-main-nav .search-toggle:hover {
    outline: none;
    color: rgb(115, 0, 210);
} */

.search-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: rgb($bgColor);
  transition: 0.3s ease-out;
  transform: translateY(100%);

  letter-spacing: 0.001em;
  font-weight: 400;

  input {
    color: rgb($color);
    font-family: $ffRegular;
    font-weight: $fwRegular;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.001em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.search-modal-container.active {
  transform: translateY(0);
}

.search-modal-container .close-btn {
  position: absolute;
  top: 65px;
  right: 1rem;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.search-modal-container .close-btn svg {
  width: 36px;
  height: 36px;
}

@media screen and (min-width: 768px) {
  .search-modal-container .close-btn {
    opacity: 0.5;
    transition: 0.3s ease-out;
  }

  .search-modal-container .close-btn:hover {
    opacity: 1;
  }
}

.searchform label {
  display: block;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.searchform .searchform__container {
  display: flex;
  flex-direction: column;
}

.searchform input[type='text'] {
  letter-spacing: 0.001em;
  font-size: 1rem;
  height: 2rem;
  border: 0;
  border-bottom: 1px solid #000;
  background-color: transparent;
  color: #000;
  outline: none;
  width: 100%;
}

.searchform input[type='text']::placeholder {
  color: #000;
}

.searchform input:focus {
  outline: none;
}

.searchform input[type='submit'] {
  font-size: 0.85rem;

  color: #000;
  border: 1px solid #000;
  border-radius: 0.7em;
  padding: 0.95rem 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  will-change: background-color, color, border-color;
  transition: background-color 200ms ease-out, color 200ms ease-out,
    border-color 200ms ease-out;
}

.searchform input[type='submit']:hover {
  color: #fff;
  background-color: #000;
}

@media (min-width: 768px) {
  .searchform .searchform__container {
    flex-direction: row;
    align-items: flex-end;
  }

  .searchform label {
    margin-bottom: 2px;
  }

  .searchform input[type='submit'] {
    width: auto;
    padding: 0.75rem 2rem 0.7rem;
    margin-bottom: 0;
  }

  .searchform input[type='text'] {
    min-width: 380px;
    margin-right: 12px;
  }
}

@media (max-width: 768px) {
  .searchform {
    width: 80%;
    max-width: 600px;
  }
}

.l-main.no-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.c-modal-link__close {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: block;
}
.c-modal-link__close::before,
.c-modal-link__close::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
  border-radius: 1px;
}

.c-modal-link__close::before {
  transform: rotate(45deg);
}

.c-modal-link__close::after {
  transform: rotate(-45deg);
}
