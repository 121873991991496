@import '../global/variables.css';
@import '../global/mixins.css';

.c-images-8 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $gutter;

  @mixin bp $tablet {
    gap: $gutterTablet;
  }

  &__title {
    display: block;
    text-align: center;
    margin-bottom: $spaceBig;
  }
  &__item {
    flex-grow: 1 0 0;
    width: calc(50% - $gutter);
    margin-bottom: $spaceBig;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @mixin bp $tablet {
      width: calc(12.5% - $gutterTablet);
      margin-bottom: $spaceBigTablet;
    }

    .s-grey & {
      color: rgb($black);
    }

    &:hover,
    .s-grey &:hover &__text {
      color: rgb($purple);
    }

    &__text {
      p {
        margin: 0;
      }
      &--italic {
        font-style: italic;
      }
    }

    &__media {
      width: 100%;

      &__thumbnail {
        margin-bottom: $spaceSmall;
        position: relative;
        display: block;
        width: 100%;
        background-color: rgb($purple);

        img:hover {
          mix-blend-mode: difference;
        }
      }
    }
  }
}
