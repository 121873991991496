.c-dev {

  &__guides {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 111111111;
    display: none;
    justify-content: center;
    pointer-events: none;

    .s-active & {
      display: flex;
    }
  }
  
  &__columns-container {
    justify-content: space-between;
    padding-right: $pageGutter;
    padding-left: $pageGutter;
    outline: 1px solid red;

    @mixin bp $tablet {
      padding-right: $pageGutterTablet;
      padding-left: $pageGutterTablet;
    }
  }

  &__column {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    outline: 1px solid blue;
    color: #FF00FF;
    text-shadow: 0 0 1em #000000;
  }
}

.xdebug-var-dump {
  position: relative;
  z-index: 1;
  background-color: white;
  border: 1px solid red;
}