@import '../global/variables.css';

.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  padding-top: $gutter;
  padding-bottom: $gutter;
  background-color: rgb($bgColor);
  will-change: background-color, color, opacity;
  transition: background-color 400ms 100ms ease-in-out,
    color 400ms 100ms ease-in-out, opacity 200ms ease-in-out;

  @mixin bp $tablet {
    padding-top: $gutterTablet;
    padding-bottom: $gutterTablet;
  }

  &,
  &__inner {
    display: flex;
    align-items: center;
  }

  &__inner {
    width: 100%;
  }

  .s-grey &,
  .page-template-template-front & {
    background-color: rgb($grey);
  }

  .s-beige &,
  .single-profile & {
    background-color: rgb($beige);
  }

  .single-media_item &,
  .s-black & {
    background-color: rgb($black);
  }

  .s-green & {
    background-color: rgb($green);
  }

  .s-purple & {
    background-color: rgb($purple);
  }

  &__gradient {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: transparent;
    background-image: linear-gradient(rgb($bgColor), rgba($bgColor, 0.0001));
    will-change: opacity;
    transition: opacity 100ms ease-in-out;
    opacity: 0;

    &--grey {
      background-image: linear-gradient(rgb($grey), rgba($grey, 0.0001));

      .s-grey &,
      .page-template-template-front &,
      .page-template-template-room-room-front &,
      .single-exhibition & {
        transition-delay: 400ms;
        opacity: 1;
      }
    }

    &--beige,
    .single-profile & {
      background-image: linear-gradient(rgb($beige), rgba($beige, 0.0001));

      .s-beige &,
      .single-profile & {
        transition-delay: 400ms;
        opacity: 1;
      }
    }

    &--black {
      background-image: linear-gradient(rgb($black), rgba($black, 0.0001));

      .s-black & {
        transition-delay: 400ms;
        opacity: 1;
      }
    }

    &--green {
      background-image: linear-gradient(rgb($green), rgba($green, 0.0001));

      .s-green & {
        transition-delay: 400ms;
        opacity: 1;
      }
    }

    &--purple {
      background-image: linear-gradient(rgb($purple), rgba($purple, 0.0001));

      .s-purple & {
        transition-delay: 400ms;
        opacity: 1;
      }
    }
  }

  &__item {
    & + & {
      margin-left: calc($gutter * 2);

      @mixin bp $tablet {
        margin-left: calc($gutterTablet * 2);
      }
    }
  }

  &__breadcrumb {
    display: none;
    white-space: nowrap;

    @mixin bp $tablet {
      display: flex;
    }

    .s-menu-open & {
      display: flex;
    }

    & > span:first-child {
      .s-menu-open & {
        display: flex;
        z-index: 2000;
        color: rgb($white);
      }
    }

    & > span:not(:first-child) {
      margin-left: 0.5rem;

      @mixin bpMax $tablet {
        display: none;
      }
    }

    & > span:not(:last-child) {
      margin-right: 0.5rem;
    }

    & > span:not(:first-child) {
      a {
        color: $dimmed;

        .s-beige & {
          color: rgb($white);
        }
        .s-green & {
          color: rgb($black);
        }
      }
    }

    br {
      display: none;
    }
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  &__language {
    position: relative;
    z-index: 2000;
    margin-right: 0;
    margin-left: $spaceBig;

    @mixin bp $tablet {
      margin-left: $spaceBigTablet;
    }

    .s-menu-open & {
      color: rgb($white);
    }

    &__list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    &__item {
      display: flex;
    }

    &__split {
      margin-right: 0.5em;
      margin-left: 0.5em;
    }

    &__link {
      &__inner {
        &.desktop {
          display: none;
          @mixin bp $tablet {
            display: block;
          }
        }
        &.mobile {
          display: block;
          @mixin bp $tablet {
            display: none;
          }
        }
      }
    }

    p {
      margin: 0;
    }
  }

  &__buttons {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    display: flex;
    gap: $gutter;
    z-index: 2000;

    @mixin bp $tablet {
      top: $gutterTablet;
    }

    span,
    svg {
      color: $dimmed;
    }

    &__button {
      color: $dimmed;
      border: 1px solid $dimmed;
      border-radius: 0.5em;
      padding: 0.35rem 1rem 0.3rem;
      text-transform: uppercase;
      cursor: pointer;
      will-change: background-color, color, border-color, box-shadow;
      transition: background-color 200ms ease-out, color 200ms ease-out,
        border-color 200ms ease-out, box-shadow 200ms ease-out;

      @mixin bpMax $tablet {
        font-size: 0.75rem;
      }

      @mixin bp $tablet {
        padding: 0.75rem 2rem 0.7rem;
      }

      &:hover {
        background-color: rgb($purple);
        color: rgb($white);
        border-color: rgb($purple);
        box-shadow: 0 0 0.5em rgb($purple);

        span,
        svg {
          color: rgb($white);
        }
      }
    }
  }

  &__search {
    margin-left: auto;
    cursor: pointer;

    &__content {
      display: none;
      /* align-items: center; */
      gap: $spaceSmall;

      @mixin bp $tablet {
        display: flex;
      }

      &__icon {
        position: relative;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        color: $black;
      }
    }
  }

  &__item.c-clock {
    position: relative;
    display: none;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid currentColor;
    border-radius: 50%;

    @mixin bp $tablet {
      display: block;
    }

    .s-menu-open & {
      color: white;
      z-index: 2000;
    }

    .c-clock__toggle {
      height: 100%;
    }
  }
}
