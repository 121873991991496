.c-form {
  &__field {
    position: relative;
  }

  &__input-container {
    position: relative;
    display: inline-block;

    &:hover {
      .c-form__input--radio,
      .c-form__input--checkbox {
        border-color: rgb($purple);
      }
    }

    &--checkbox {
      position: relative;
      top: .1em;
      width: 1rem;
      height: 1rem;
    }
  }

  &__label {
    cursor: pointer;

    &:hover {
      .s-black & {
        color: rgb($purple);
      }

      .c-form__input {
        &--checkbox,
        &--radio {
          .s-black & {
            border-color: rgb($purple);
          }
        }
      }
    }
  }

  &__input__check {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgb($purple);
    border-color: rgb($purple);
    box-shadow: 0 0 .5em rgb($purple);
    opacity: 0;
  }

  &__input {
    appearance: none;
    background-color: transparent;
    color: rgb($grey);
    width: 100%;

    &__check,
    &--radio,
    &--checkbox {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 5px;
      outline: none;

      &:checked {
        & + .c-form__input__check {
          opacity: 1;
        }
      }
    }

    &--radio,
    &--checkbox {
      border: 1px solid currentColor;

      &:checked {
        border-color: rgb($purple);
        color: rgb($purple);

        & ~ span {
          color: rgb($purple);
        }
      }
    }

    &--tag {
      opacity: 0;

      &:checked {
        & ~ span {
          border-color: rgb($purple);
          background-color: rgb($purple);
          color: rgb($white);
          box-shadow: 0 0 .5em rgb($purple);
        }
      }

      &:checked:hover ~ span {
        background-color: transparent;
      }

      & ~ span {
        display: block;
        padding: 1em  2em;
        border: 1px solid currentColor;
        border-radius: 15px;
      }
    }

    &--search {
      padding: .5em 1em .4em;
      background-color: rgb($white);
      border: none;
      border-radius: 2em;
      color: rgb($black);
      font-size: 1em;
      text-align: center;
      outline: none;

      &::placeholder {
        color: rgb($greyDark);
      }
    }
  }

  &__option {
    &--tag {
      &:hover {
        span {
          backgorund-color: transparent;
          border-color: rgb($purple);
          color: rgb($purple);
        }
      }
    }
  }
}

.c-input {
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid currentColor;
}

::placeholder {
  color: currentColor;
}