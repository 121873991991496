@import '../global/variables.css';
@import '../global/mixins.css';

.c-single-exhibition {
  padding: 0 10px;

  @mixin bp $tablet {
    padding: 0 20px;
  }

  &__logo {
    position: relative;

    & svg {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
    margin-bottom: $spaceMedium;

    @mixin bp $tablet {
      margin-bottom: $spaceHuge;
      gap: 30px;
    }

    & h2,
    & p {
      margin: 0;
      font-size: 16px;
      line-height: 1.2;

      @mixin bp $tablet {
        font-size: 35px;
      }
    }

    &__title {
      color: rgb($green);
      &__artist {
        font-style: italic;
        color: rgb($green);
      }
    }

    &__dates {
      display: flex;
      flex-direction: column;
      flex: 1;
      color: rgb($purple);
    }
  }

  &__links-gradient {
    position: sticky;
    bottom: 36px;
    left: 0;
    margin-left: -10px;
    margin-right: -10px;
    height: 1rem;
    background-image: linear-gradient(rgb($grey, 0.0001), rgba($grey));
    will-change: opacity;
    transition: opacity 100ms ease-in-out;

    @mixin bp $tablet {
      opacity: 0;
    }
  }

  &__links {
    position: sticky;
    bottom: 0;
    margin: 0 -10px 10px -10px;
    padding: 10px;
    width: calc(100% + 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb($grey);

    @mixin bp $tablet {
      margin: 0 -20px 10px -20px;
      padding: 20px;
      width: calc(100% + 40px);
      background-color: transparent;
    }

    & a {
      text-decoration: none;
      line-height: 1;
      margin: 0;
    }

    &__exhibitions span {
      color: rgb($green);
      font-size: 16px;

      @mixin bp $tablet {
        font-size: 20px;
      }
    }

    &__about span {
      color: rgb($purple);
      font-size: 16px;

      @mixin bp $tablet {
        font-size: 20px;
      }
    }

    & span:hover {
      @mixin bp $tablet {
        color: rgb($greyDark);
      }
    }
  }

  &__content {
    margin-bottom: $spaceGiant;

    @mixin bp $tablet {
      margin-bottom: $spaceGiantTablet;
    }
  }
  /* if there is an element with the class ".c-text-inner" */

  & .c-text__inner {
    @mixin bp $tablet {
      grid-column-start: 4;
      grid-column-end: span 6;
    }
  }
}

.c-page.c-page--exhibition {
  margin-top: 65px;

  @mixin bp $tablet {
    margin-top: 20px;
  }
}
