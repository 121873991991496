.c-main-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 100dvh;
  background-color: rgb($black);
  pointer-events: none;
  will-change: opacity;
  transition: opacity 400ms ease-in-out;
  opacity: 0;

  .s-menu-open & {
    pointer-events: auto;
    opacity: 1;
  }

  &__list-item {
    margin: 2.25rem 0;
    line-height: 1;

    @mixin bp $tablet {
      margin: 2.72rem 0;
    }

    &.has-children {
      cursor: pointer;
    }

    &.inactive {
      span {
        color: $dimmed;
        transition: color 200ms ease-out;
      }
      span:hover {
        color: rgb($purple);
      }
    }
  }

  &__submenu {
    display: flex;
    height: 0;
    justify-content: center;
    gap: 1rem;
    padding: 0;
    list-style-type: none;
    flex-direction: column;
    overflow: hidden;

    &__item {
      &:first-child {
        @mixin bpMax $tablet {
          margin-top: 2.6rem;
        }
      }
    }

    @mixin bp $tablet {
      gap: $spaceBig;
      flex-direction: row;

      &__item {
        margin: 3.6rem 0 3rem 0;
      }
    }

    &__item {
      font-size: 1.25rem;

      @mixin bp $tablet {
        font-size: 1.5rem;
      }
    }
  }

  &__buttons {
    position: absolute;
    left: 50%;
    bottom: $gutterTablet;
    transform: translateX(-50%);
    z-index: 2000;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $gutter;

    @mixin bp $tablet {
      top: $gutterTablet;
      gap: $gutterTablet;
      bottom: auto;
      width: auto;
    }

    span,
    svg {
      color: $dimmed;
    }

    &__button {
      width: 100%;
      color: $dimmed;
      border: 1px solid $dimmed;
      border-radius: 0.5em;
      padding: 0.875rem 2rem 0.625rem;
      display: flex;
      justify-content: center;
      white-space: nowrap;
      cursor: pointer;
      will-change: background-color, color, border-color, box-shadow;
      transition: background-color 200ms ease-out, color 200ms ease-out,
        border-color 200ms ease-out, box-shadow 200ms ease-out;
      font-size: 1.0625rem;

      @mixin bp $tablet {
        padding: 0.75rem 2rem 0.7rem;
        width: auto;
      }

      &:hover {
        background-color: rgb($purple);
        color: rgb($white);
        border-color: rgb($purple);
        box-shadow: 0 0 0.5em rgb($purple);

        span,
        svg {
          color: rgb($white);
        }
      }

      &__search {
        margin-left: auto;
        cursor: pointer;

        &__content {
          display: flex;
          gap: $spaceSmall;

          &__icon {
            position: relative;
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            color: $black;
          }
        }
      }
    }
  }

  &__inner {
    display: block;
    align-items: flex-start;
    height: 100vh;
    text-align: center;

    @mixin bpMax $tablet {
      display: block;
    }

    @mixin bp $tablet {
      grid-template-rows: 1fr 1fr 1fr;
    }
  }

  &__nav {
    margin-top: $spaceGiant;
    margin-bottom: auto;
    grid-column-end: span $columns;
    leading: 1;
    font-size: 2.8125rem;

    @mixin bp $tablet {
      position: fixed;
      margin-top: 0;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 6.25rem;
    }

    &--primary:not(:last-child) {
      @mixin bpMax $tablet {
        flex: 0 1 auto;
        margin-bottom: $spaceBig;
      }
    }

    a {
      color: rgb($white);

      &:hover {
        color: rgb($purple);
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__toggle {
    position: relative;
    z-index: 2000;
    text-transform: uppercase;

    .s-menu-open & {
      color: white;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: rgb($greyDark);

    @mixin bpMax $tablet {
      display: none;
    }

    a:hover {
      color: rgb($purple);
    }

    input[type='submit'] {
      &:hover {
        background-color: rgb($purple);
        color: rgb($white);
        border-color: rgb($purple);
        box-shadow: 0 0 0.5em rgb($purple);
      }
    }
  }

  .c-footer__contact {
    margin-top: $spaceMediumTablet;
    margin-bottom: $spaceMediumTablet;
  }

  /**
   * search
   */
  .searchform {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;

    .main-nav-search,
    .main-nav-search-button {
      font-size: 2.125rem; /* slightly smaller than surrounding text */
      border-radius: 5px;
      width: 100%;
      width: calc((100% / 2) - (1rem / 2));
      gap: 1rem;
    }

    .main-nav-search {
      margin-bottom: 1rem;
    }

    .main-nav-search-button {
      background-color: rgb($purple);
      color: #fff;
    }
  }
}
