@import '../global/variables.css';
@import '../global/mixins.css';

body.post-type-archive-event {
  font-family: $ffRegular;
  color: var(--theme-black);

  .event-archive-header {
    text-align: center;
    margin: var(--spacing-5) auto;
  }

  .event-archive-header,
  .event-archive {
    max-width: calc(var(--max-width-content) + (2 * var(--spacing-2)));
    padding: 0 var(--spacing-2);
  }

  .event-archive {
    margin: 0 auto;

    .event-archive__filters {
      margin-bottom: var(--spacing-5);
    }

    .event-archive__filters {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-3);
    }

    .event-archive__navigation {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: $spaceBig;

      @mixin bp $tablet {
        gap: $spaceBigTablet;
      }
    }

    .navigation-link {
      border: none;
      background-color: transparent;
      border-radius: none;
      padding: 0;
      color: var(--theme-black);

      &:hover {
        cursor: pointer;
        color: var(--theme-gray);
      }

      &--active {
        color: var(--theme-gray);
      }

      &--active:hover {
        color: #000;
      }
    }

    .event-archive__years {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      gap: 0.1875rem;
    }

    .year {
      position: relative;

      &__label {
        display: table-cell;
        vertical-align: middle;
        font-size: clamp(1.25rem, 4vw, 1.5rem);
        text-align: center;
        cursor: pointer;
        color: #000;
      }

      &__input {
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
        height: 0;
        width: 0;
      }

      &__input:hover + .year__label,
      &__input:checked + .year__label {
        color: var(--theme-gray);
      }
    }

    .event-archive__list {
      display: flex;
      flex-flow: column nowrap;
      gap: var(--spacing-2);

      &.event-archive__list--no-result {
        justify-content: center;
        align-items: center;
      }
    }

    .event {
      padding: var(--spacing-2) var(--spacing-2) 2rem;
      background-color: var(--theme-gray);
      border-radius: 0.625rem;

      &:hover {
        cursor: pointer;
        background-color: var(--theme-purple);
        box-shadow: 0 0 0.75rem var(--theme-purple);
        color: #fff;
      }

      & a {
        text-decoration: none;
      }

      &__inner {
        display: flex;
        flex-flow: column nowrap;
        gap: var(--spacing-2);

        @media screen and (max-width: 1040px) {
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        @media screen and (min-width: 1040px) {
          flex-flow: row nowrap;
        }
      }

      &__meta {
        @media screen and (min-width: 1040px) {
          flex: 1;
        }
      }

      .meta-item {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.125rem;
      }

      &__main {
        @media screen and (min-width: 1040px) {
          flex: 2;
        }
      }

      &__title {
        line-height: calc(50 / 45);
      }
    }

    .event.open-call {
      color: var(--theme-purple);

      &:hover {
        color: #fff;
      }
    }

    &__pagination {
      grid-column: 1 / span 12;
      margin-top: var(--spacing-5);
      display: flex;
      justify-content: center;

      .page-numbers {
        border: none;
        box-shadow: none;

        &.dots {
          cursor: default;
        }
      }

      button.page-numbers:not(.dots) {
        color: #000;
        transition: background-color 150ms ease-in-out;

        &:hover {
          background-color: var(--theme-gray);
        }
      }

      button.prev:disabled,
      button.next:disabled {
        color: var(--theme-gray);
        cursor: default;

        &:hover {
          background-color: #fff !important;
        }
      }
    }

    .event-archive__pagination--hidden {
      opacity: 0;
      visibility: hidden;
    }

    .event-archive__loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding-top: 5rem;
      transition: 0.3s background ease-out;
      z-index: -1;

      &.loading {
        z-index: 1;
        background: rgba(255, 255, 255, 0.5);

        .lds-ring {
          display: inline-block;
        }
      }
    }

    .lds-ring {
      display: none;
      position: relative;
      width: 5rem;
      height: 5rem;
    }

    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 4rem;
      height: 4rem;
      margin: 0.5rem;
      border: 0.5rem solid #000;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #000 transparent transparent;
    }

    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
