@define-mixin bp $width {
  @media screen and (width >= $width) {
    @mixin-content;
  }
}

@define-mixin bpH $height {
  @media screen and (height >= $height) {
    @mixin-content;
  }
}

@define-mixin bpMax $width {
  @media screen and (width < $width) {
    @mixin-content;
  }
}

@define-mixin bpHMax $height {
  @media screen and (height < $height) {
    @mixin-content;
  }
}

@define-mixin bpMenuShrunk {
  @media screen and (width > $tablet) and (height < $tablet) {
    @mixin-content;
  }
}