.featured-event {
  &:hover {
    cursor: pointer;
  }

  & a {
    display: block;
  }

  &__image {
    position: relative;
    display: block;
    height: 0px;
    padding-bottom: calc(2 / 3 * 100%);
    overflow: hidden;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .caption {
      display: block;
      font-size: 0.9375rem;
      margin-top: var(--spacing-1);
    }
  }
}

.c-upcoming-events-selected .featured-event {
  margin-top: 0;
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  & a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    position: relative;
    display: block;
    height: 0px;
    padding-bottom: calc(2 / 3 * 100%);
    overflow: hidden;

    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .caption {
      display: block;
      font-size: 0.9375rem;
      margin-top: var(--spacing-1);
    }
  }
}
