@import "../../global/variables.css";
@import "../../global/mixins.css";

.tribe-events-view {
  &--list {
    @mixin bp $tablet {
      display: none;
    }

    .tribe-events-header {
      display: none;
    }
  }

  &--month {
    @mixin bpMax $tablet {
      display: none;
    }
  }
}

body {
  .tribe-events-ajax-loading,
  .tribe-events-view-loader {
    display: none;
  }

  .tribe-common {
    .tribe-common-l-container  {
      max-width: 100%;
      padding: 0;
    }

    .tribe-events-calendar-month-nav {
      display: none;
    }

    button {
      border: none;
    }
  }

  .tribe-events-header {
    @mixin bpMax $tablet {
      display: none;
    }

    &__events-bar {
      display: none;
    }
  }

  .tribe-common .tribe-events-c-top-bar {
    flex-direction: flex-start;
  }

  .tribe-events-c-top-bar__nav-list-item {
    font-size: 1rem;
  }

  .tribe-events .tribe-events-c-top-bar__nav-list {
    display: flex;
  }

  .tribe-events .tribe-events-c-top-bar__nav-list-item {
    flex: none;
    height: 1.5rem;
    margin-top: 0.3rem;
    padding-right: 15px;

    @mixin bp $huge {
      height: 2.2625rem;
    }

    a,
    svg {
      height: 100%;
    }
  }

  .tribe-common-c-btn-icon {
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: auto;
    padding: 0;
    text-decoration: none;
    width: auto;

    &--caret-left,
    &--caret-right {
      &:before {
        width: 12px;
        height: 20px;
      }
    }

    &--caret-left:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='20' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath d='M11.916 17.841L10 19.757l-9.9-9.9L10-.041l1.916 1.916-7.983 7.984z' fill='%23bababa'/%3E%3C/svg%3E");
    }
    
    &--caret-right:before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='20' xmlns='https://www.w3.org/2000/svg'%3E%3Cpath d='M.084 2.159L2 .243l9.9 9.9L2 20.041.084 18.126l7.983-7.984z' fill='%23bababa'/%3E%3C/svg%3E");
    }
  }

  .tribe-events-c-top-bar__today-button.tribe-common-a11y-hidden {
    width: auto;
    flex: 0 1 auto;
    color: rgb($black);
    padding: .75rem ​2rem 0.7rem;
    border: 1px solid rgb($black);
    border-radius: .5em;
    font-size: 1rem;
    background: transparent;
    will-change: background-color, color, border-color, box-shadow;
    transition: background-color 200ms ease-out, color 200ms ease-out, border-color 200ms ease-out, box-shadow 200ms ease-out;

    &:hover {
      background-color: rgb($purple);
      border-color: rgb($purple);
      color: rgb($white);
      box-shadow: 0 0 .5em rgb($purple);
    }
  }

  .tribe-common .tribe-common-h--alt {
    font-weight: 400;
  }

  .tribe-common .tribe-common-h3 {
    color: #141827;
    font-family: Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.5;
  }

  .tribe-events .tribe-events-c-top-bar__datepicker-button {
    align-items: center;
    display: flex;
    flex: none;
  }

  .tribe-common--breakpoint-medium.tribe-events .tribe-events-c-top-bar__datepicker {
    flex: auto;
    position: relative;
  }

  .tribe-common--breakpoint-medium.tribe-common .tribe-common-h3 {
    font-size: 28px;
    line-height: 1.42;
}

  .c-calendar {
    &__nav svg {
      display: none;
    }

    a {
      &:hover {
        color: rgb($purple);
      }
    }

    &__month {
      .tribe-common-l-container {
        display: flex;
        flex-direction: column;

        @mixin bp $tablet {
          /* height: 100vh; */
        }
      }
    }

    .tribe-events-c-ical {
      display: none;
    }
  }

  .single-tribe_events .tribe-events-view--month {
    @mixin bpMax $tablet {
      display: none;
    }
  }

  .tribe-events .tribe-events-calendar-month-mobile-events__mobile-day {
    display: none;
  }

  .post-type-archive-tribe_events .tribe-events .tribe-events-calendar-month-mobile-events__mobile-day {
    @mixin bpMax $tablet {
      display: block;
    }
  }

  .tribe-events-calendar-month,
  .c-calendar__categories {
    @mixin bpMax $tablet {
      display: none;
    }
  }
}

article.tribe-events-calendar-list__event {
  background-color: rgb($greyDark);
  display: block;
  padding: 1.25rem 2rem 1.25rem;
  border-radius: .75rem;
  text-decoration: none;
  text-align: center;
  will-change: color, background-color, border-color;
  transition: color 200ms ease-out, background-color 200ms ease-out, border-color 200ms ease-out;

  &.tribe-common-g-row--gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .tribe-events-calendar-list__event-title-link {
    text-transform: none;
    font-weight: normal;

    &:hover {
      text-decoration: none;
      border-bottom: none;
    }
  }

  .tribe-common-h6 {
    font-size: 1em;
  }
}