@import '../global/variables.css';
@import '../global/mixins.css';

.c-scroll-to-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gutterTablet;
  margin-bottom: $spaceHugeHuge;

  @mixin bp $tablet {
    flex-direction: row;
  }

  &__item {
    width: fit-content;
    color: $dimmed;
    border: 1px solid $dimmed;
    border-radius: 0.5em;
    padding: 0.9rem 2.5rem 0.9rem;
    white-space: nowrap;
    cursor: pointer;
    will-change: background-color, color, border-color, box-shadow;
    transition: background-color 200ms ease-out, color 200ms ease-out,
      border-color 200ms ease-out, box-shadow 200ms ease-out;
    font-size: 1rem;

    h4 {
      font-size: 1rem;
      color: $dimmed;
      will-change: color;
      transition: color 200ms ease-out;
    }

    &:hover {
      color: rgb($black);
      border-color: rgb($black);

      h4 {
        color: rgb($black);
      }
    }
  }
}
