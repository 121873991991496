@import "../../global/variables.css";
@import "../../global/mixins.css";

.tribe-events-pg-template {
  &,
  &#tribe-events-pg-template {
    padding: 0;
  }

  & > .tribe-events-ajax-loading + a {
    display: none;
  }
}

.c-calendar__day {
  grid-column-end: span 12;
  font-size: 1rem;

  @mixin bp $tablet {
    grid-column-end: span 6;
    position: relative;
    top: -3rem;
    padding-left: calc($gutterTablet * .5);
  }


  @mixin bp $laptopBig {
    padding-left: $gutterTablet;
  }

  &--upcoming {
    @mixin bpMax $tablet {
      display: none;
    }
  }

  /* @mixin bp $laptop {
    margin-top: 4.8rem;
  }

  @mixin bp $laptopBig {
    margin-top: 4.9rem;
  }

  @mixin bp $huge {
    margin-top: 5.1rem;
  }

  @mixin bp $giant {
    margin-top: 5.3rem;
  } */

  &--intro {
    display: none;
    /* margin-top: 6rem; */
    /* height: calc(100vh - 6rem); */
    text-align: center;

    @mixin bp $tablet {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 6.2rem);
    }

    @mixin bp $laptopBig {
      height: calc(100vh - 6.3rem);
    }

    @mixin bp $huge {
      height: calc(100vh - 6.5rem);
    }

    @mixin bp $giant {
      height: calc(100vh - 6.7rem);
    }

    &.lang--da .c-calendar__day__now--en {
      display: none;
    }

    &.lang--en .c-calendar__day__now--da {
      display: none;
    }


    p {
      margin: 0;
    }

    svg {
      position: relative;
      top: -2.25rem;
      width: 75%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.c-event {
  margin-bottom: $spaceHuge;

  @mixin bp $tablet {
    margin-bottom: $spaceHugeTablet;
  }

  @mixin bp $huge {
    margin-bottom: $spaceHugeHuge;
  }

  &__header {
    text-align: center;
  }

  &__event-pagination {
    display: flex;
    justify-content: space-between;

    a {
      color: $dimmed;
      text-decoration: none;
    }

    &__next {
      margin-right: 0;
    }
  }

  &__headline-container {
    margin-top: $spaceMedium;

    @mixin bp $tablet {
      margin-top: 4.9rem;
    }

    @mixin bp $huge {
      margin-top: 4.45rem;
    }
  }

  &__categories {
    font-style: $fsItalic;

    &__label {
      display: none;
    }

    p {
      margin: 0;
    }

    a {
      text-decoration: none;
    }
  }

  &__date-time {
    margin-top: $spaceMedium;
    margin-bottom: $spaceMedium;
  }

  &__links {
    display: flex;
    justify-content: center;
    align-items: center;

    .tribe-events-cal-links {
      &,
      .tribe-events-gcal {
        margin: 0;
      }
    }

    a {
      font-size: .875rem;
      color: $dimmed;
      text-decoration: none;

      @mixin bp $tablet {
        font-size: .9375rem;
      }
    }

    .tribe-events-cal-links a:not(:last-child),
    &__facebook {
      /* margin-right: 2rem; */
      
      @mixin bp $tablet {
        /* margin-right: 5rem; */
      }
    }
  }

  .c-events-on-day + & {
    margin-top: $spaceBig;

    @mixin bp $tablet {
      margin-top: $spaceBigTablet;
    }
  }

  .c-text__inner a {
    color: rgb($purple);
  }

  .c-slideshow {
    padding-right: $gutter;
    padding-left: $gutter;

    @mixin bp $tablet {
      padding-right: $gutterTablet;
      padding-left: $gutterTablet;
    }

    &__inner {
      grid-column-start: 1;
      grid-column-end: span 12;
    }
  }

  .c-media-and-caption {
    padding-right: 0;
    padding-left: 0;
  }
}

.single-tribe_events a.tribe-events-gcal,
.single-tribe_events a.tribe-events-gcal:hover,
.single-tribe_events a.tribe-events-ical,
.single-tribe_events a.tribe-events-ical:hover {
  color: rgb($greyDark);
}

.single-tribe_events .c-calendar__month {
  @mixin bpMax $tablet {
    display: none;
  }
}