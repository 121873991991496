.c-hamburger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__link {
    position: fixed;
    top: $pageGutter;
    right: $pageGutter;
    z-index: 1;
    display: block;
    width: 1.7em;
    height: 1.7em;
    

    @mixin bp $tablet {
      display: none;
    }
  }

  &__line {
    position: absolute;
    left: calc((100% - 1em) / 2 + 1px);
    width: calc(1em - 1px);
    height: 1px;
    background-color: currentColor;

    &--one {
      top: calc(50% - 2px);
    }

    &--two {
      top: calc(50% + 2px);
    }
  }
}