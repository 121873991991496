@import '../global/variables.css';
@import '../global/mixins.css';

.c-event-teaser {
  text-align: center;

  &--small {
    display: block;
    width: 100%;
    padding: 1.25rem 2rem 1.25rem;
    border-radius: 0.75rem;
    text-decoration: none;
    border: 1px solid rgb($black);
    will-change: color, background-color, border-color;
    transition: color 200ms ease-out, background-color 200ms ease-out,
      border-color 200ms ease-out;

    @mixin bpMax $tablet {
      font-size: 0.875rem;
    }

    @mixin bp $tablet {
      padding: 2.25rem 4rem 2.5rem;
    }

    p {
      margin: 0;
    }
  }

  &--large {
    padding: $spaceMedium $gutter $gutter;
    background-color: rgb($greyDark);
    border-radius: 10px;

    @mixin bp $tablet {
      padding: 1.25rem;
    }
  }

  &--large:hover &__content,
  &--large &__content:hover {
    color: rgb($white);
  }

  &:hover,
  a&--active {
    border-color: rgb($purple);
    background-color: rgb($purple);
    box-shadow: 0 0 0.75rem rgb($purple);

    &,
    &:hover {
      color: rgb($white) !important;
    }
  }

  & + & {
    margin-top: 1.25rem;
    @mixin bp $tablet {
      margin-top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title,
  &__programs {
    margin: 0;
  }

  &__title {
    font-weight: $fwRegular !important;
  }

  &__info,
  .tribe-common &__info {
    margin-top: $spaceMedium;
    margin-bottom: $spaceMedium;
    font-weight: $fwRegular;

    @mixin bp $tablet {
      margin-top: $spaceMediumTablet;
      margin-bottom: $spaceMediumTablet;
    }
  }

  &__thumbnail {
    margin-top: auto;
    margin-bottom: 0;
  }
}
