@import '../global/variables.css';
@import '../global/mixins.css';

.c-programs-overview {
  margin-bottom: $spaceHugeHuge;
  grid-column-start: 1;
  grid-column-end: 13;

  @mixin bp $tablet {
    grid-column-start: 3;
    grid-column-end: 11;
    margin-bottom: $spaceHugeHuge;
  }
  &__title {
    display: block;
    text-align: center;
    margin-bottom: $spaceBig;

    @mixin bp $tablet {
      margin-bottom: $spaceBigTablet;
    }
  }

  &__program {
    display: flex;
    border-top: 2px solid rgb($black);
    margin-bottom: $spaceBig;
    flex-direction: column-reverse;
    gap: 1rem;

    @mixin bp $tablet {
      margin-bottom: $spaceBigTablet;
      gap: $gutterTablet;
      flex-direction: row;
    }

    &:hover,
    .s-grey &:hover &__text__headline,
    .s-grey &:hover span,
    .s-grey &:hover &__text__description {
      color: rgb($purple);
    }

    &__text {
      width: 100%;

      &__headline {
        display: block;
        text-align: center;
        margin-top: 0;
        text-transform: none;

        @mixin bp $tablet {
          text-align: left;
          margin-top: 1.625rem;
        }

        .s-grey & {
          color: rgb($black);
        }
      }

      &__description,
      p {
        margin-bottom: 0px;
      }

      &__description,
      span {
        margin-top: $spaceSmall;
        margin-bottom: 0;
        color: rgb($black);

        @mixin bp $tablet {
          margin-top: 2rem;
        }
      }
    }
    &__media {
      width: 100%;
      &__thumbnail {
        margin-top: $spaceMediumTablet;
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 66.66666%;
        overflow: hidden;

        .c-media {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          &__inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}
