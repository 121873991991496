@import '../global/variables.css';
@import '../global/mixins.css';

.c-images-4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $gutter;

  @mixin bp $tablet {
    gap: $gutterTablet;
  }

  &__title {
    display: block;
    text-align: center;
    margin-bottom: $spaceBig;
  }
  &__item {
    width: calc(50% - $gutter);
    margin-bottom: $spaceBig;

    @mixin bp $tablet {
      width: calc(25% - $gutterTablet);
      margin-bottom: $spaceBigTablet;
    }

    .s-grey & {
      color: rgb($black);
    }

    &:hover,
    .s-grey &:hover &__text {
      color: rgb($purple);
    }

    &__text {
      p {
        margin: 0;
      }
      &--italic {
        font-style: italic;
      }
    }

    &__media {
      width: 100%;

      &__thumbnail {
        margin-bottom: $spaceSmall;
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 66.66666%;
        overflow: hidden;
        background-color: rgb($purple);

        .c-media {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          &__inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &:hover {
              mix-blend-mode: difference;
            }
          }
        }
      }
    }
  }
}
