body.single-event .single-event-wrapper {
	display: flex;
	flex-flow: column nowrap;
	padding: var(--spacing-4) var(--spacing-2) 0;

	@media screen and (min-width: 1040px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, min-content);
		column-gap: var(--spacing-7);
	}

	.single-event__image {
		order: 2;
		position: relative;

		@media screen and (min-width: 1040px) {
			grid-area: 1 / 1 / 4 / 2;
		}
	}

	.single-event__image-inner {
		@media screen and (min-width: 1040px) {
			position: sticky;
			top: 6rem;
		}

		& img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.caption {
			display: block;
			font-size: 0.9375rem;
			margin-top: var(--spacing-1);
		}
	}

	.single-event__header {
		order: 1;

		@media screen and (min-width: 1040px) {
			grid-area: 1 / 2 / 2 / 3;
		}
	}

	.single-event__return-link,
	.single-event__categories,
	.single-event__event-title,
	.single-event__date-time {
		display: block;
		text-align: center;
	}

	.single-event__return-link {
		text-decoration: underline;
		text-underline-offset: 0.3125rem;
		text-decoration-color: transparent;
		font-size: 0.9375rem;
		line-height: 1.33333;
		color: rgb(165, 166, 169);
		margin-bottom: var(--spacing-5);

		@media screen and (min-width: 1040px) {
			margin-bottom: var(--spacing-7);
		}

		&:hover {
			text-decoration-color: rgb(165, 166, 169);
		}
	}

	.single-event__categories p {
		font-style: italic;
		margin: 0;
	}

	.single-event__event-title,
	.single-event__categories {
		font-size: clamp(1.75rem, 4vw, 3.125rem);
		line-height: calc(50 / 45);
	}

	.single-event__event-title {
		margin: 0 0 var(--spacing-5);

		@media screen and (min-width: 1040px) {
			margin: 0 0 var(--spacing-7);
		}
	}

	.single-event__date-time {
		display: flex;
		flex-flow: column nowrap;
		gap: 0.125rem;
		text-align: center;
		margin-bottom: var(--spacing-5);

		@media screen and (min-width: 1040px) {
			margin-bottom: 7.5rem;
		}

		.meta-item {
			display: block;
		}
	}

	.single-event__excerpt {
		order: 3;
		margin: var(--spacing-5) 0 var(--spacing-2);

		@media screen and (min-width: 1040px) {
			grid-area: 2 / 2 / 3 / 3;
			margin: 0 0 var(--spacing-2);
		}

		&::after {
			content: "\007e";
		}

		& p {
			margin: 0 0 var(--spacing-2);
		}
	}

	.single-event__content {
		order: 4;

		@media screen and (min-width: 1040px) {
			grid-area: 3 / 2 / 4 / 3;
		}

		& > *:first-child {
			margin-top: 0;
		}

		& a {
			text-decoration: none;
			color: rgb(115, 0, 210);

			&:hover {
				color: #000;
			}
		}
	}
}
