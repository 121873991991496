/* @font-face {
  font-family: 'ArialNarrowRegular';
  src:  url('../../fonts/ArialNarrowMTPro-Regular.woff2') format('woff2'),
        url('../../fonts/ArialNarrowMTPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'ArialNarrowBold';
  src:  url('../../fonts/ArialNarrowMTPro-Bold.woff2') format('woff2'),
        url('../../fonts/ArialNarrowMTPro-Bold.woff') format('woff');
} */

body {
  color: rgb($color);
  font-family: $ffRegular;
  font-weight: $fwRegular;
  font-size: 1rem;
  line-height: 1.2;
  letter-spacing: 0.001em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  will-change: color;
  color: currentColor;

  .s-grey &,
  .c-news-post & {
    color: rgb($purple);

    &:hover {
      color: rgb($black);
    }
  }

  .s-black &,
  .s-green & {
    color: rgb($purple);

    &:hover {
      color: rgb($grey);
    }
  }

  .s-purple & {
    color: rgb($black);

    &:hover {
      color: rgb($grey);
    }
  }

  .s-beige &,
  .c-profile & {
    color: rgb($white);

    &:hover {
      color: rgb($grey);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

h1,
.f-h1 {
  font-size: 2.8125rem;
  line-height: 1;

  @mixin bp $mobileLarge {
    font-size: 3.25rem;
  }

  @mixin bp $laptop {
    font-size: 4.375rem;
  }

  @mixin bp $huge {
    font-size: 6.25rem;
  }
}

h2,
.f-h2,
h3,
.f-h3 {
  font-size: 1.75rem;
  line-height: 1.1428571429;

  @mixin bp $mobileLarge {
    font-size: 2rem;
    line-height: 1.125;
  }

  @mixin bp $laptop {
    font-size: 2.25rem;
    line-height: 1.11111;
  }

  @mixin bp $huge {
    font-size: 3.125rem;
    line-height: 1.1;
  }
}

h3,
.f-h3 {
  text-transform: uppercase;
}

h4,
.f-h4,
.c-text h3 {
  font-size: 1rem;
  line-height: 1.3125;
  text-transform: uppercase;

  @mixin bp $mobileLarge {
    font-size: 1.125rem;
    line-height: 1.2777777778;
  }

  @mixin bp $huge {
    font-size: 1.25rem;
    line-height: 1.25;
  }
}

.f-italic {
  font-style: italic;
}

.f-caption {
  font-size: 0.625rem;
  line-height: 1.5;

  @mixin bp $mobileLarge {
    font-size: 0.75rem;
    line-height: 1.4166666667;
  }

  @mixin bp $laptop {
    font-size: 0.875rem;
    line-height: 1.3571428571;
  }
}

.f-country-codes {
  position: relative;
  top: 0.1em;
  font-size: 0.6666em;
}
