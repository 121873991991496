.c-newsletter {
  &__inner {
    grid-column-end: span 12;

    @mixin bp $tablet {
      grid-column-start: 3;
      grid-column-end: span 8;
    }

    @mixin bp $laptop {
      grid-column-start: 4;
      grid-column-end: span 6;
    }

    @mixin bp $desktop {
      grid-column-start: 5;
      grid-column-end: span 4;
    }
  }

  form {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  input {
    background-color: transparent;
    color: rgb($white);
    font-size: 1em;
    outline: none;
  }

  input[type="email"] {
    flex: 1 1 auto;
    height: 1.5rem;
    margin-right: 1.25rem;
    /* font-size: 0.75rem; */
    appearance: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $dimmed;
  }

  input[type="submit"] {
    color: $dimmed;
    border: 1px solid currentColor;
    border-radius: .5em;
    padding: 0.35rem 1rem 0.3rem;
    text-transform: uppercase;
    cursor: pointer;
    will-change: background-color, color, border-color, box-shadow;
    transition: background-color 200ms ease-out, color 200ms ease-out, border-color 200ms ease-out, box-shadow 200ms ease-out;

    @mixin bpMax $tablet {
      font-size: 0.75rem;
    }

    @mixin bp $tablet {
      padding: 0.75rem 2rem 0.7rem;
    }

    &:hover {
      background-color: rgb($black);
      color: rgb($white);
      border-color: rgb($black);
      box-shadow: 0 0 .5em rgb($black);
    }
  }

  .c-headline + & {
    margin-top: 3rem;
  }

  .c-text + &,
  .c-text-columns + &,
  .c-slideshow + &,
  .c-media-and-caption + & {
    margin-top: 3.75rem;
  }

  ::-webkit-input-placeholder { /* Edge */
    color: $dimmed;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $dimmed;
  }

  ::placeholder {
    color: $dimmed;
  }
}