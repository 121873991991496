.pagination.navigation {
	text-align: center;
}

.nav-links {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	border-radius: 0.3125rem;
	font-size: 1.125rem;
}

a.page-numbers,
button.page-numbers {
	color: #000;
	transition: background-color 150ms ease-in-out;

	&:hover {
		background-color: var(--theme-gray);
		cursor: pointer;
	}
}

.page-numbers {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: content-box;
	color: var(--theme-gray);
	font: 1.125rem;
	font-weight: 700;
	text-decoration: none;
	border-radius: 6.25rem;
	min-width: 1.5rem;
	line-height: 2.375rem;
	height: 2.375rem;
	padding: 0 0.5rem;
	margin: 0 0.5rem;

	@media screen and (min-width: 721px) {
		line-height: 2.875rem;
		height: 2.875rem;
		padding: 0 0.75rem;
		margin: 0 0.625rem;
	}
}

.page-numbers.current {
	color: #000;
	font-weight: 700;
	border: 0;
	padding: 0 0.4375rem;
	border-radius: 6.25rem;
	background-color: var(--theme-gray);

	@media screen and (min-width: 721px) {
		padding: 0 0.6875rem;
	}
}

.page-numbers.prev,
.page-numbers.next {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.125rem;

	&::after {
		display: flex;
	}
}

.page-numbers.prev {
	border: 0;
	margin-right: 0.375rem;
	margin-left: 0;

	&::after {
		content: url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' width='6.27' height='11' viewBox='0 0 6.27 11'%3E%3Cpolygon points='5.5 11 0 5.5 5.5 0 6.27 .784 1.554 5.5 6.27 10.216'/%3E%3C/svg%3E");
	}

	@media screen and (min-width: 721px) {
		margin-right: 1.5625rem;
	}
}

.page-numbers.next {
	border: 0;
	margin-left: 0.375rem;
	margin-right: 0;

	&::after {
		content: url("data:image/svg+xml,%3Csvg xmlns='https://www.w3.org/2000/svg' width='6.27' height='11' viewBox='0 0 6.27 11'%3E%3Cpolygon points='.77 11 0 10.216 4.716 5.5 0 .784 .77 0 6.27 5.5'/%3E%3C/svg%3E");
	}

	@media screen and (min-width: 721px) {
		margin-left: 1.5625rem;
	}
}

.pagination__icon {
	display: inline-block;

	@media screen and (min-width: 721px) {
		&--prev {
			margin-right: 0.3125rem;
		}

		&--next {
			margin-left: 0.3125rem;
		}
	}
}

.pagination__text {
	display: none;

	@media screen and (min-width: 721px) {
		display: block;
	}
}
