:root {
  /* Grid */
  --max-width-content: 83.75rem;

  /* Spacing */
  --spacing-1: 0.625rem;
  --spacing-2: 1.25rem;
  --spacing-3: 1.875rem;
  --spacing-4: 2.5rem;
  --spacing-5: 3.125rem;
  --spacing-6: 3.75rem;
  --spacing-7: 4.375rem;

  /* Colors */
  --theme-purple: rgb(115, 0, 210);
  --theme-gray: rgb(165, 166, 169);
  --theme-black: rgb(0, 0, 0);
}
