@import "../../global/variables.css";
@import "../../global/mixins.css";

body {

  .tribe-common {
    .tribe-common-h3 {
      font-family: $ffRegular;
    }
  }

  .tribe-events {
    &.tribe-common--breakpoint-xsmall .tribe-events-header,
    &.tribe-common--breakpoint-medium .tribe-events-header,
    .tribe-events-header {
      /* margin: 3rem 0 0; */
      margin: 0;
      padding: 0;
    }

    &.tribe-common--breakpoint-medium .tribe-events-header {
      width: calc(100% - 2rem);
    }

    &.tribe-common--breakpoint-medium .tribe-events-header__events-bar {
      margin: 0;
    }

    .tribe-events-header {
      background-color: transparent;

      @mixin bp $tablet {
        width: 100%;
      }

      @mixin bp $huge {
        width: calc(100% - $gutterTablet * 1);
        
      }
    }

    .tribe-events-c-events-bar__views {
      display: none;
    }

    &.tribe-common--breakpoint-medium {
      .tribe-events-c-top-bar {
        margin-bottom: 0;
      }

      .tribe-events-l-container {
        padding: 0;
        min-height: auto;
      }

      .tribe-events-calendar-month__day {
        border: none;
      }
    }

    .tribe-events-calendar-month {
      display: flex;
      flex-direction: column;
      height: 70vw;

      @mixin bp $tablet {
        height: calc(100vh - 5.7rem);
        height: calc(100vh - 7rem);
      }

      @mixin bp $huge {
        height: calc(100vh - 9.5rem);
        height: calc(100vh - 11rem);
      }

      &__day {
        width: auto;
        height: auto;
        overflow: visible;
      }

      /* Today */
      &__day--current .tribe-events-calendar-month__day-date,
      &__day--current .tribe-events-calendar-month__day-date-link,
      &__day--current .c-calendar__month__event-link {
        color: rgb($black);
        font-weight: $fwLight;
      }

      /* Past */
      &__day--past,
      &__day--past .tribe-events-calendar-month__day-date-link,
      &__day--past .c-calendar__month__event-link {
        color: rgb($greyDark);
      }

      &__header,
      &__week {
        display: flex;
        flex: 1;
      }

      &__header {
        .tribe-common-a11y-visual-hide {
          display: none;
        }

        .tribe-common-b3 {
          font-size: 1em;
          color: rgb($black);
        }    
      }

      &__header-row,
      &__week {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        width: 100%;
      }

      &__header-column {
        display: flex;
        align-items: center;
      }

      &__header-column-title-desktop {
        display: none;
      }

      .tribe-events &__day {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: visible;

        &--past {
          color: $dimmed;
        }
      }

      &__day-date-daynum {
        display: block;
        width: 100%;
      }

      &__day-cell {
        width: 100%;

        &--mobile {
          display: none;
        }

        &--desktop {
          line-height: 1;
        }
      }

      &__day-date {
        .tribe-common-a11y-visual-hide {
          display: none;
        }
      }

      &__day-date-link {
        position: relative;
        display: block;
        text-decoration: none;
        will-change: color;

        &:before,
        &:after {
          content: "";
          position: absolute;
          will-change: opacity;
          transition: opacity 200ms ease-in;
        }
          
        &:before {
          top: -.01em;
          left: -.32em;
          width: 1.71875em;
          height: 1.546875em;
          z-index: -1;
          background-color: rgb($purple);
          border-radius: .9375rem;
          box-shadow: 0 0 1rem rgb($purple);
          opacity: 0;
        }

        &:after {
          bottom: -.1em;
          left: .05em;
          width: .975em;
          height: 1px;
          background-color: currentColor;
          border-radius: 1px;

          @mixin bp $laptop {
            left: .05em;
            height: 2px;
          }

          .tribe-events-calendar-month__day[aria-labelledby$="10"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="11"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="12"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="13"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="14"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="15"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="16"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="17"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="18"] &,
          .tribe-events-calendar-month__day[aria-labelledby$="19"] & {
            left: .1em;
            width: .95em;
          }
        }

        &.s-active,
        &:hover {
          color: rgb($white);

          &:before {
            opacity: 1;
          }

          &:after {
            /* opacity: 1; */
          }
        }
      }

      &__calendar-event-tooltip {
        position: fixed;
        bottom: calc($pageGutter + 2.7rem);
        left: $pageGutter;
        width: calc(50% - $gutterTablet * 4);
        display: flex;
        font-size: 1rem;
        text-decoration: none;
        display: none;
      }

      &__calendar-event-tooltip-title-link {
        text-decoration: none;
      }

      &__calendar-event-title,
      &__calendar-event-datetime,
      &__calendar-event-tooltip-featured-image-wrapper,
      &__multiday-event {
        display: none;
      }
    }

    .datepicker {
      .day,
      .month,
      .year {
        &.active {
          &.current {
            background-color: rgba($purple);
          }

          &,
          &.focused,
          &:focus,
          &:hover {
            background-color: rgb($purple);
          }

        }
      }
    }
  }

  .tribe-common {
    p {
      font-weight: $fwLight;
    }

    .tribe-events-c-top-bar__datepicker {
      flex: 0 1 100%;
    }

    .tribe-events-c-top-bar__datepicker-button {
      text-align: center;
      flex: 1 1 auto;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .tribe-events-calendar-month {
    @mixin bp $tablet {
      font-size: 2rem;
    }

    @mixin bp $laptop {
      font-size: 2.5rem;
    }

    @mixin bp $laptopBig {
      font-size: 3rem;
    }

    @mixin bp $huge {
      font-size: 4rem;
    }

    @mixin bp $giant {
      font-size: 5rem;
    }

    @media screen and (min-width: $laptopBig) and (height < 900px) {
      font-size: 3rem;
    }

    @media screen and (height < 700px) {
      font-size: 2rem;
    }

    @media screen and (height < 600px) {
      font-size: 1.5rem;
    }

    @media screen and (height < 500px) {
      font-size: 1rem;
    }

    .touch-device & {
      width: calc(100% + $gutterTablet * 1.5);
      font-size: 1rem;
    }

    &,
    &__header .tribe-common-b3,
    h3 {
      font-weight: $fwLight;
    }

    .tribe-common-l-container {
      display: flex;
      flex-direction: column;

      @mixin bp $tablet {
        height: 100%;
      }
    }
  }


  .tribe-events-calendar-month {
    
  }
  
  .c-calendar {
    max-width: none;

    &__nav {
      @mixin bp $tablet {
        padding-right: .75em;
      }

      &__month,
      &__year {
        position: relative;
        top: -0.05em;
      }

      &__month {
        body.lang-da & {
          text-transform: lowercase;
        }
      }

      &__year {
        @mixin bpMax $laptopBig {
          display: none;
        }
      }
    }

    &__month {
      grid-column-end: span 12;
      font-weight: $fwLight;

      @mixin bp $tablet {
        /* background-color: grey; */
        grid-column-end: span 6;
        position: fixed;
        bottom: 1.7rem;
        display: flex;
        flex-direction: column;
        /* width: calc((100% - $pageGutterTablet * 2 - $gutterTablet * ($columns - 1)) / $columns * 6 + $gutterTablet * 5); */
        width: calc(50% - $gutterTablet * 2);
        top: $spaceBigTablet;
        /* top: calc($spaceBigTablet + 2rem); */
      }

      @mixin bp $laptopBig {
        width: calc(50% - $gutterTablet * 2.5);
      }

      @mixin bp $huge {
        /* top: $spaceBigTablet; */
      }

      &__prev,
      &__next {
        a.tribe-common-c-btn-icon:before {
          content: none;
        }

        &--disabled {
          opacity: .25;
        }

        svg {
          width: 2.375rem;
          height: 100%;
        }
      }

      &__prev {
        order: -1;
      }

      &__next {
        order: 2;
      }
    }

    &__day {
      @mixin bp $tablet {
        grid-column-start: 7;
        grid-column-end: span 6;
      }
    }

    .tribe-events-c-ical {
      display: none;
    }

    &__day-summary {
      margin-top: auto;
      margin-bottom: 0;
      font-size: 1rem;

      p {
        margin: .5em 0;
      }
    }

    &__categories {
      display: grid;
      grid-gap: .15rem $gutter;
      margin: .5rem 0 0 0;
      padding: 0;
      list-style-type: none;
      font-weight: $fwRegular;
      
      @mixin bp $tablet {
        grid-gap: .15rem $gutterTablet;
        grid-template-columns: 1fr 1fr;
      }

      &__toggle {
        width: 1em;
        height: 1em;
        margin-right: 0.5rem;
        border: 1px solid currentColor;

        .current-menu-item &,
        .current_page_item &,
        .current-tribe_events_cat-ancestor & {
          background-color: currentColor;
        }
      }

      p {
        margin: .5em 1rem 0 0;
      }

      a {
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;
        
        &,
        .c-form__input {
          color: $dimmed;
        }
        
        &:hover {
          &,
          .c-form__input {
            color: rgb($purple);
          }
        }

        
        &.current-menu-item,
        &.current_page_item,
        &.current-tribe_events_cat-ancestor {
          color: rgb($purple);

          &:hover {
            color: rgb($purple);
          }
        }

      }
    }
  }

  .tribe-events-calendar-month-mobile-events {
    order: -1;

    @mixin bp $tablet {
        margin-bottom: 1rem;
      }

    &__mobile-day {
      display: none;
    }
  }

  .tribe-events-c-nav__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @mixin bpMax $tablet {
      width: calc(100% - $gutterTablet * 1.5);
    }
  }

  .tribe-events-c-nav__list-item {
    &--prev,
    &--next {
      width: 2.625rem; 
      height: 2.625rem;
      height: .75em;

      p,
      svg {
        width: 100%;
        height: 100%;
      }

      svg {
        @mixin bpMax $tablet {
          width: auto;
        }
      }

      svg {
        display: block;
      }
    }

    a:hover path {
      stroke: rgb($purple);
    }

    p {
      display: block;
      margin: 0;

      path {
        stroke: $dimmed;
      }
    }

    &--next {
      p {
        @mixin bpMax $tablet {
          display: flex;
          justify-content: flex-end;
        }
      }

      svg {
        @mixin bpMax $tablet {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }

    path {
      stroke-width: 4;
    }

    .tribe-events-c-nav__prev svg {
      /* transform: rotate(180deg); */
    }
  }

  .tribe-events-calendar-month {
    

  }

  .tribe-events-view--month {
    @mixin bp $tablet {
      width: calc(100% + 3rem);
    }
  }

  .tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__week {
    border-left: none;
  }

  .tribe-common--breakpoint-medium.tribe-common .tribe-common-b3 {
      font-size: 1em;
  }


  .tribe-common--breakpoint-medium.tribe-events {
    .tribe-events-calendar-month__header-column-title-mobile {
      display: block !important;
      visibility: visible;
      font-size: 1em;
    }
    .tribe-events-calendar-month__header-column-title-desktop {
      display: none !important;
    }
  } 


  .tribe-events {
    .tribe-events-calendar-month {
      &__header-column-title-mobile {
        font-family: $ffRegular;
      }

      &__header-column-title-desktop {
        display: none !important;
      }

      &__day--current {
        .tribe-events-calendar-month__day-date-link,
        .c-calendar__month__event-link {
          &:hover,
          &:focus {
            color: rgb($white);
          }
        }
      }
    }

    .tribe-events-c-top-bar__datepicker-container {
      width: 100%;
    }

    .datepicker {
      top: 1rem !important;
      left: 0 !important;
      width: 100%;
      margin: 0;
      padding: .75rem .75rem 1.5rem;
      background-color: rgb($grey);
      border-radius: 1rem;
      box-shadow: 0 0 1rem rgb($grey);
      text-transform: uppercase;

      @mixin bp $tablet {
        left: -3.5rem !important;
        width: calc(100% + 6.125rem);
      }

      table {
        width: 100%;

        th:after,
        .datepicker-switch,
        .year,
        .month {
          font-size: 1.4375rem;
        }

        .datepicker-switch {
          width: 50%;

          &:hover {
            background-color: transparent;
            color: rgb($color)
          }
        }

        th.prev,
        th.next {
          position: relative;
          width: 25%;

          &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb($grey);
            font-weight: normal;
          }
        }

        th.prev:after {
          content: "<";
        }

        th.next:after {
          content: ">";
        }

        tbody {
          position: relative;
          z-index: 1;
        }

        .year,
        .month {
          height: 3.75rem;

          &.focused {
            background-color: transparent;
          }

          &.current,
          &.current:hover,
          &.active,
          &.active:hover,
          &:hover {
            background-color: rgb($purple);
            border-radius: 1rem;
            box-shadow: 0 0 1rem rgb($purple);
            color: rgb($white);
          }          
        }
      }
    }
  }

  .tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__day:after {
    content: none;
  }

  
  .tribe-common .tribe-events-calendar-month__header {
    display: flex;
    align-items: center;
  }
  .tribe-events .tribe-events-calendar-month__header-column {
    padding-bottom: 0;
  }

  .tribe-common--breakpoint-medium.tribe-events .tribe-events-calendar-month__day {
    min-height: auto;
  }

  .tribe-common .tribe-common-c-btn-border, .tribe-common a.tribe-common-c-btn-border,
  .tribe-events .datepicker .datepicker-switch,
  .tribe-events .datepicker .month, .tribe-events .datepicker .year {
    font-family: $ffRegular;
  }

  .tribe-events-calendar-month__week {
    align-items: center;
  }

  .tribe-events-c-top-bar.tribe-events-header__top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .tribe-events-view--month .tribe-events-c-top-bar__today-button.tribe-common-a11y-hidden,
  .tribe-common .tribe-events-view--month .tribe-events-c-top-bar__nav.tribe-common-a11y-hidden {
    display: block!important;
    visibility: visible;
  }

  .tribe-common--breakpoint-medium.tribe-common .tribe-common-c-btn-border,
  .tribe-common--breakpoint-medium.tribe-common a.tribe-common-c-btn-border,
  .tribe-common .tribe-common-c-btn-border, .tribe-common a.tribe-common-c-btn-border {
    padding: 1em;
  }

  .tribe-events-c-top-bar__today-button.tribe-common-a11y-hidden {
    margin-right: 1em;
  }

  .tribe-events-view-loader.tribe-common-a11y-hidden {
    opacity: 0;
    display: block !important;
  }

  .tribe-events .tribe-events-view-loader {
    background-color: rgb($bgColor);
    display: block;
    will-change: opacity;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
    opacity: 1;
  }

  .tribe-events-view-loader__dots.tribe-common-c-loader {
    display: none;
  }

}

/* List view */
body {
  .tribe-events-view--list .tribe-events-header {
    display: flex;
  }
}

.tribe-events .tribe-events-calendar-list-nav {
  display: none;
}

.tribe-events-c-top-bar__nav {
  /* display: none !important; */
}