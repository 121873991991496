.arthub-blocks.events {
	.breadcrumbs {
		display: flex;
		flex-flow: row nowrap;
		gap: 0.2188rem;

		&__group {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
		}

		&__item {
			position: relative;
			display: inline-block;
			opacity: 1;
		}
	}

	.event {
		&__image {
			margin: auto 0 0;
		}

		&__image img {
			width: 100%;
			object-fit: cover;
			aspect-ratio: 2/3;
		}
	}
}
