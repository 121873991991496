.c-page {
  min-height: 100vh;
  margin-top: $spaceHuge;
  will-change: opacity;
  transition: opacity 200ms ease-in-out;
  opacity: 0;

  .s-modules-loaded & {
    opacity: 1;
  }

  @mixin bp $tablet {
    margin-top: $spaceHugeTablet;
  }

  @mixin bp $huge {
    margin-top: $spaceHugeHuge;
  }

  &--calendar {
    @mixin bpMax $tablet {
      margin-top: 4.5rem;
    }

    @mixin bp $tablet {
      margin-top: $spaceBigTablet;
    }

    @mixin bp $huge {
      margin-top: $spaceBigTablet;
    }
  }

  a {
    text-decoration: none;
  }

  &__header {
    grid-column-end: span 12;
    text-align: center;

    @mixin bp $tablet {
      grid-column-start: 3;
      grid-column-end: span 8;
    }

    &__links {
      display: flex;
      justify-content: center;
      margin: $spaceBig 0 0 0;
      padding: 0;
      list-style-type: none;
      font-size: 0.75rem;

      @mixin bp $tablet {
        margin-top: $spaceBigTablet;
      }

      &__item:not(:last-child) {
        margin-right: 5rem;
      }

      &__link {
        text-decoration: none;
        color: currentColor;
        opacity: 0.8;
      }
    }
  }

  &__ingress,
  &__columns {
    a:not(.c-slideshow__navigation__arrow) {
      /* .s-beige & {
        color: rgba($white);
      }

      .s-green & {
        color: rgb($black);
      }

      .s-grey & {
        color: rgb($purple);
      }

      .s-black &,
      .s-purple & {
        color: $dimmed;
      } */
    }
  }

  &__ingress {
    margin-top: $spaceBig;

    @mixin bp $tablet {
      margin-top: $spaceBigTablet;
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__columns {
    margin-top: $spaceBig;
    margin-bottom: $spaceGiant;

    @mixin bp $tablet {
      margin-top: $spaceBigTablet;
      margin-bottom: $spaceGiantTablet;
    }

    &__column {
      grid-column-end: span 12;
    }

    &--1,
    &--2 {
      font-size: 1rem;
    }

    &--2 {
      .c-page__columns__column {
        @mixin bp $tablet {
          grid-column-end: span 5;
        }

        &:first-child {
          @mixin bp $tablet {
            grid-column-start: 2;
          }
        }
      }
    }

    &--3 {
      .c-page__columns__column {
        grid-column-end: span 12;

        @mixin bp $tablet {
          grid-column-end: span 4;
        }
      }
    }

    .c-headline {
      @mixin bpMax $tablet {
        height: auto !important;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__close {
    position: fixed;
    top: calc($pageGutter - 8px);
    right: calc($pageGutter - 1px);
    z-index: 1;
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;

    @mixin bp $tablet {
      top: calc($pageGutterTablet - 8px);
      right: calc($pageGutterTablet - 8px);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: 5px;
      width: 40px;
      height: 2px;
      background-color: currentColor;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .c-media-and-caption {
    padding-right: $gutter;
    padding-left: $gutter;

    @mixin bp $tablet {
      padding-right: $gutterTablet;
      padding-left: $gutterTablet;
    }
  }
}

.tribe-events-c-subscribe-dropdown__container {
  display: none !important;
}
