@import "../global/variables.css";
@import "../global/mixins.css";

.c-intro {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgb($purple);
  color: rgb($white);
  font-size: 1rem;
  text-align: center;
  overflow: hidden;
  will-change: opacity;
  transform: translateZ(0);

  &.s-completed {
    display: none;
  }

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    grid-column-start: 1;
    grid-column-end: span 12;
    will-change: opacity;
    transition: opacity 100ms ease-out;
    opacity: 0;
    
    /* @mixin bp $tablet {
      grid-column-start: 3;
      grid-column-end: span 8;
    }

    @mixin bp $laptopBig {
      grid-column-start: 4;
      grid-column-end: span 6;
    } */
  }

  &__images {
    /* mix-blend-mode: difference; */
  }

  &__images,
  &__image {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image {
    display: flex;
    justify-content: center;
    opacity: 0;
    
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }
  }

  &.s-ready &__inner {
    opacity: 1;
  }
}