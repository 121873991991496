.l-columns-container {
  display: grid;
  grid-gap: $gutter;
  grid-template-columns: repeat(12, 1fr);

  @mixin bp $tablet {
    grid-gap: $gutterTablet;
  }

  &:not([class*="l-column--"]) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &--outmost {
    padding-right: $pageGutter;
    padding-left: $pageGutter;

    @mixin bp $tablet {
      padding-right: $pageGutterTablet;
      padding-left: $pageGutterTablet;
    }
  }
}