.c-lazyload {
  &--image {
    will-change: opacity;
    transition: opacity 400ms easeInOutQuad;
    opacity: 0;

    &.s-loaded {
      opacity: 1;
    }
  }
}