/* BREAKPOINTS */
$small: 0px;
$mobileMedium: 350px;
$mobileLarge: 400px;
$tablet: 764px;
$laptop: 1024px;
$laptopBig: 1280px;
$desktop: 1440px;
$huge: 1560px;
$giant: 1920px;

$iPhone5Height: 568px;

/* Z-INDEX */
$zHeader: 9000;
$zBelow: -1;
$zBottomlessPit: -9000;

/* SIZES */
$columns: 12;
$gutter: 0.625rem; /* 10px */
$gutterTablet: 1.25rem; /* 20px */
$pageGutter: $gutter; /* 10px */
$pageGutterTablet: $gutterTablet; /* 20px */

$spaceSmall: 0.6875rem; /* 11px */
$spaceMedium: 1.375rem; /* 22px */
$spaceBig: 3.125rem; /* 50px */
$spaceHuge: 6.25rem; /* 100px */
$spaceGiant: 9.375rem; /* 150px */
$spaceSmallTablet: $spaceSmall; /* 11px */
$spaceMediumTablet: 2.1875rem; /* 35px */
$spaceBigTablet: 4.375rem; /* 70px */
$spaceHugeTablet: 4.375rem; /* 70px */
$spaceGiantTablet: 12.5rem; /* 200px */
$spaceHugeHuge: 7.5rem; /* 120px */

/* COLORS */
$white: 255, 255, 255;
$beige: 210, 202, 179;
$purple: 115, 0, 210;
$grey: 239, 239, 240;
$greyDark: 165, 166, 169;
$green: 0, 174, 24;
$dimmed: #9d9fa2;
$black: 0, 0, 0;
$bgColor: $grey;
$color: $black;

/* FONTS */
$ffRegular: neue-haas-unica, sans-serif;
$fwBold: 600;
$fwRegular: 400;
$fwLight: 300;
$fsItalic: italic;
